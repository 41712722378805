$(document).ready(function () {
  var searchTrigger = $('.search-btn-outside'),
      searchBox = $('#search');

  if(searchTrigger.length && searchBox.length) {
    searchTrigger.click(function () {
      searchBox.fadeIn();
      searchBox.addClass('is-active');
      return false;
    });
  }

  $(document).on('click', function (e) {

    var element = e.target;

    if(!searchBox.is(element) && searchBox.has(element).length === 0) {
      searchBox.fadeOut();
    }

  });

});